<template>
  <v-main>
    <AppShellsNavbar />
    <div class="d-flex flex-column align-center pt-16">
      <slot />
    </div>
  </v-main>
</template>

<style lang="scss">
.auth-page {
  padding: 2rem;
  border-radius: 12px;
  box-shadow: $box-shadow;
  display: grid;
  gap: 1rem;
  min-width: 15rem;
  max-width: 30rem;
  width: 100%;
  &__form {
    display: grid;
    gap: 1rem;
    &--two-columns {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      grid-gap: 1rem;
    }
  }
}
</style>
